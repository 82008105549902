import revive_payload_client_4sVQNw7RlN from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/apex-pos/apex-pos/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_w2VlvAEcpa from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_Y6agwlnXZq from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_http_Yqhs1a69RT from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/01.http.js";
import broadcast_CK3DX0HYCo from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/broadcast.js";
import calc_WyxXt4L56Q from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/calc.js";
import firebase_Tc1lVUbp7M from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/firebase.js";
import format_Tg1NzmYztx from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/format.js";
import posthog_client_o9IEgU9LwG from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/posthog.client.js";
import snackbar_ozQSg3RwSv from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/snackbar.js";
import vuetify_8NhHJigKc1 from "/home/runner/work/apex-pos/apex-pos/frontend/plugins/vuetify.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_w2VlvAEcpa,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  _01_http_Yqhs1a69RT,
  broadcast_CK3DX0HYCo,
  calc_WyxXt4L56Q,
  firebase_Tc1lVUbp7M,
  format_Tg1NzmYztx,
  posthog_client_o9IEgU9LwG,
  snackbar_ozQSg3RwSv,
  vuetify_8NhHJigKc1
]