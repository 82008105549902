import { defineStore } from "pinia";

export const useAnalyticsStore = defineStore("analyticsStore", {

  state: () => ({

    trackingId: null,

    isFirstVisit: false,

    pixelId: [487731410912551, 524446913413293], 

    initialized: false,

    fbq: null,

    $posthog: useNuxtApp().$posthog,
  }),


  actions: {

    init() {

      if (this.isDevMode()) {
        return
      }
      
      this.initFbq()

      this.initTagManager()

      //this.initHotjar()

      this.setTrackingId()

      this.initialized = true
    },


    isDevMode() {
      return process.env.NODE_ENV === 'development'
    },

    visitSignup() {
      this.trackFbqEvent('Lead')
    },

    signUp() {
      this.trackFbqEvent('StartTrial')
    },

    contact() {
      this.trackFbqEvent('Contact')
    },

    initiateCheckout() {
      this.trackFbqEvent('InitiateCheckout')
    },

    addPaymentInfo() {
      this.trackFbqEvent('AddPaymentInfo')
    },

    subscribe() {
      this.trackFbqEvent('Subscribe')
    },

    trackFbqEvent(event) {
      if (!this.initialized) {
        this.init()
      }

      if (this.fbq) {
        this.fbq('track', event)
      }

    },

    track(eventName) {
      if (this.isDevMode()) {
        return
      }

      const posthog = this.$posthog()

      posthog.capture(eventName)
    },

    sendAppLog(event, metadata) {

      const { $http } = useNuxtApp()

      $http.$post('event', { event: event, metadata : metadata })

    },

    setTrackingId() {
      
      this.trackingId = localStorage.getItem('trackingId')

      if (!this.trackingId) {

        this.isFirstVisit = true

        this.trackingId = this.generateUuid()

        localStorage.setItem('trackingId', this.trackingId)
      }
    },

    extractQueryMetadata(query)  {
      const utmParams = [
        "utm_id",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ];
    
      return utmParams.reduce((params, param) => {
        if (query[param]) {
          params[param] = query[param];
        }
        return params;
      }, {});
    },

    startRecording() {
      if (this.isDevMode()) {
        return
      }

      const posthog = this.$posthog()

      posthog.startSessionRecording()
      
      console.log('[analytics] Session recording started...')
    },

    initFbq() {

      this.loadFbqScript()

      this.fbq = window.fbq

      if (this.fbq) {

        this.pixelId.forEach(id => {
          this.fbq('init', id)
        })

        console.log('fbq initialized')
      }
    },

    loadFbqScript() {
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            // rome-ignore lint/style/noArguments: <explanation>
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    },

    initHotjar() {
      this.loadHotjarScript()
      console.log('hotjar initialized')
    },

    loadHotjarScript() {
      (function (h, o, t, j, a, r) {
        // rome-ignore lint/style/noArguments: <explanation>
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: 3483803, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    },

    initTagManager() {
      this.loadTagManagerScript()
      console.log('tag manager initialized')
    },

    loadTagManagerScript() {
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
          // rome-ignore lint/style/useSingleVarDeclarator: <explanation>
          // rome-ignore lint/style/noVar: <explanation>
        }); var f = d.getElementsByTagName(s)[0],
          // rome-ignore lint/style/useTemplate: <explanation>
          // rome-ignore lint/suspicious/noDoubleEquals: <explanation>
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            // rome-ignore lint/style/useTemplate: <explanation>
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-T7QGKCK');
    },

    generateUuid() {
      let d = new Date().getTime();

      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
      }

      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    }
  }
})  