<template>
  <v-app>
    <NuxtLayout>
      <NuxtPage />
      <AppLoading />
    </NuxtLayout>
    <ErrorHandler />
    <VitePwaManifest />
    <AppSnackbar />
  </v-app>
</template>

<script setup>
const analytics = useAnalyticsStore();

onMounted(() => {
  analytics.init();

  if (process.env.NODE_ENV === "production") {
    console.log(
      "%c Powered by: Apex Software   \n https://apexsoftware.com.br ",
      "background: #111C29; color: #fff"
    );
  }
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,500;1,400&display=swap");

.v-application {
  font-family: "Montserrat", sans-serif;
}

.v-card,
.v-card-text {
  font-family: "Montserrat", sans-serif;
}

.v-list {
  font-family: "Montserrat", sans-serif;
}

.iconify {
  vertical-align:sub;
}
</style>
